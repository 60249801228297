//
// index.tsx - Org. Home page
//

import {
  useGetCurrentOrganizationId,
  useGetSubscriptionDetails,
} from "@custom-hooks/billing";
import { useGetUserProjects } from "@custom-hooks/projects";
import { useCheckAuth } from "@custom-hooks/useCheckAuth";
import { AddonLookupKey } from "@data-types/billing-types";
import { Project, Projects } from "@data-types/projects-types";
import { ApplicationLayout } from "@layouts/ApplicationLayout/ApplicationLayout";
import BoltIcon from "@layouts/svg-icon/bolt-icon.svg";
import RocketBadge from "@layouts/svg-illustration/rocket-badge.svg";
import { SQLITE_CLOUD_ORGANIZATION_ID } from "@lib/billing/constants";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import SubscriptionStatusBanner from "@tw-components/billing/SubscriptionStatusBanner";
import { ContactDialog } from "@tw-components/organization-home/ContactDialog";
import { CreateProjectCard } from "@tw-components/organization-home/CreateProjectCard";
import { ProjectCard } from "@tw-components/organization-home/ProjectCard";
import { UpgradingInfraBanner } from "@tw-components/organization-home/UpgradingInfraBanner";
import { CreateProjectDialog } from "@tw-components/projects/CreateProjectDialog";
import { Button } from "@tw-components/ui/button";
import clsx from "clsx";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

/**
 * Default Component
 *
 * This component displays a list of user projects with options to filter, create, and manage projects.
 * It shows an infrastructure upgrade banner if needed, a project creation dialog, an upgrade prompt,
 * and filtered projects based on a search query.
 *
 * @returns {JSX.Element} The rendered Default component.
 */
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

function Default() {
  // Check authentication status
  useCheckAuth(true, false);

  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);

  // Set up router
  const router = useRouter();
  const { query } = router;

  const [projectsRefreshInterval, setProjectsRefreshInterval] = useState<
    number | undefined
  >(undefined);

  const {
    data: projects,
    hasData: availableProjects,
    isLoading: loadingProjects,
  } = useGetUserProjects(true, projectsRefreshInterval);

  const { hasSubscription, subscriptionStatusAlertProps } =
    useGetSubscriptionDetails();

  const organizationId = useGetCurrentOrganizationId();

  /**
   * Filters projects based on the provided search query.
   * @param {Projects} items - List of user projects to filter.
   * @param {string} query - The search query for filtering.
   * @returns {Projects} The filtered list of projects.
   */
  function searchItems(items: Projects, query: string): Projects {
    if (!query) return items;
    const lowerCaseQuery = query.toLowerCase();
    return items.filter((item: Project) => {
      const lowerCaseName = item.name.toLowerCase();
      const lowerCaseDescription = item.description.toLowerCase();
      const lowerCaseId = item.id.toLowerCase();
      return (
        lowerCaseName.includes(lowerCaseQuery) ||
        lowerCaseDescription.includes(lowerCaseQuery) ||
        lowerCaseId.includes(lowerCaseQuery)
      );
    });
  }

  // Set up state and handlers for project filtering
  const [triggerFiltering, setTriggerFiltering] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<Projects>([]);
  const [searchProjectsQuery, setSearchProjectsQuery] = useState<string>(
    (query.q as string) || ""
  );

  // Debounced handler to trigger filtering
  const handleTriggerFilter = debounce(() => {
    setTriggerFiltering((prev) => !prev);
  }, 150);

  // Update search query state and trigger filtering
  const handleUpdateSearchProjectsQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchProjectsQuery(event.target.value);
    handleTriggerFilter();
  };

  // Update filtered projects and URL based on search query
  useEffect(() => {
    const currentPath = router.asPath.split("?")[0];
    const newUrl = searchProjectsQuery
      ? `${currentPath}?q=${searchProjectsQuery}`
      : currentPath;

    if (availableProjects) {
      const results = searchProjectsQuery
        ? searchItems(projects as Projects, searchProjectsQuery)
        : (projects as Projects);
      setFilteredProjects(results);
    } else {
      setFilteredProjects([]);
    }

    router.push(newUrl, undefined, { shallow: true });
  }, [availableProjects, projects, triggerFiltering, searchProjectsQuery]);

  useEffect(() => {
    const projectsWithNodesInCreation = projects?.filter(
      (project) => project.creating_count > 0
    );

    if (projectsWithNodesInCreation && projectsWithNodesInCreation.length > 0) {
      setProjectsRefreshInterval(10000);
    } else {
      if (projectsRefreshInterval !== undefined) {
        setProjectsRefreshInterval(undefined);
      }
    }
  }, [projects]);

  // Render component UI
  return (
    <>
      <div className="tw-flex tw-h-screen tw-flex-col tw-overflow-hidden">
        {!loadingProjects &&
          availableProjects &&
          organizationId === SQLITE_CLOUD_ORGANIZATION_ID && (
            <div className="tw-sticky tw-top-0 tw-mx-auto tw-mb-4 tw-grid tw-w-full tw-max-w-[109rem]">
              <form
                style={{ width: "100%" }}
                noValidate
                autoComplete="off"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <TextField
                  value={searchProjectsQuery}
                  onChange={handleUpdateSearchProjectsQuery}
                  className="subvariant-modal-form"
                  type="search"
                  fullWidth
                  placeholder="Search Projects"
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                />
              </form>
            </div>
          )}

        <div className="tw-flex-1 tw-overflow-auto">
          <div className="tw-relative tw-mx-auto tw-grid tw-w-full tw-max-w-[109rem] tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-4 3xl:tw-grid-cols-9">
            {/* Infrastructure Upgrade Banner */}
            {UPGRADING_INFRA?.toLowerCase() === "true" && (
              <div
                className={clsx(
                  "tw-col-span-1 sm:tw-col-span-2 3xl:tw-col-span-3",
                  "tw-rounded-3xl tw-border tw-p-5",
                  "tw-bg-semantics-informational10-light dark:tw-bg-semantics-informational10-dark",
                  "tw-dark:tw-border-interface-divider-dark tw-border-interface-divider-light"
                )}
              >
                <UpgradingInfraBanner
                  onContactButtonClicked={() => setShowContactDialog(true)}
                />
              </div>
            )}

            {/* Show loading skeleton if data is loading */}
            {loadingProjects ? (
              <div
                className={clsx(
                  "tw-col-span-1 tw-min-h-48 sm:tw-col-span-2 3xl:tw-col-span-3",
                  "tw-rounded-3xl",
                  "tw-bg-interface-gray-light dark:tw-bg-interface-gray-dark",
                  "tw-animate-pulse"
                )}
              ></div>
            ) : (
              <>
                <CreateProjectCard width="tw-col-span-1 sm:tw-col-span-2 3xl:tw-col-span-3" />

                {filteredProjects.map((project) => {
                  return (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      width="tw-col-span-1 sm:tw-col-span-2 3xl:tw-col-span-3"
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>

        <div className="tw-sticky tw-bottom-0 tw-z-20 tw-mt-3 tw-w-full">
          {subscriptionStatusAlertProps && (
            <SubscriptionStatusBanner {...subscriptionStatusAlertProps} />
          )}

          {/* Upgrade prompt for Sandbox plan */}
          {hasSubscription === false &&
            subscriptionStatusAlertProps === undefined && (
              <div className="tw-dark:tw-bg-brandBlues-pale-dark tw-mx-auto tw-w-full tw-max-w-[109rem] tw-rounded-2xl tw-bg-brandBlues-pale-light tw-p-6">
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                  <div className="tw-flex tw-flex-col tw-gap-y-2">
                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
                      <div className="tw-size-7 tw-shrink-0">
                        <RocketBadge />
                      </div>
                      <div className="tw-dark:tw-text-brandBlues-brandDark-dark tw-text-h5-mob tw-text-brandBlues-brandDark-light sm:tw-text-h5-desk">
                        Try SQLite Cloud Pro for free until March 1st
                        {/** Upgrade to SQLite Cloud Pro */}
                      </div>
                    </div>
                    <div className="tw-dark:tw-text-text-subTitle-dark tw-text-14px-regular tw-text-text-subTitle-light">
                      Manage team members, create more projects, and get more
                      storage with SQLite Cloud Pro.
                    </div>
                  </div>
                  <Button
                    onClick={() => setShowCreateProjectDialog(true)}
                    variant="primary"
                    icon={<BoltIcon />}
                    label={"Create Pro Project"}
                  />
                </div>
              </div>
            )}
        </div>
      </div>

      <CreateProjectDialog
        isOpen={showCreateProjectDialog}
        onClose={() => setShowCreateProjectDialog(false)}
        projectType={AddonLookupKey.PRO_PROJECT}
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
        type={"contact_us"}
      />
    </>
  );
}

// Specify layout for Default component
Default.getLayout = function getLayout(page: React.ReactNode) {
  return <ApplicationLayout>{page}</ApplicationLayout>;
};

export default Default;
